import {Component, OnDestroy, OnInit} from '@angular/core';
import * as Rellax from 'rellax';
import {ClientComment} from '../app-common/model/client-comment.model';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    showAlert = false;
    focus1: boolean;
    focus: boolean;
    clientComments: ClientComment[] = [];

    constructor(private httpClient: HttpClient) {
    }

    ngOnInit() {
        this.loadComments();
        // var rellaxHeader = new Rellax('.rellax-header');
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('landing-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }

    ngOnDestroy() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('landing-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

    closeAlert() {
        this.showAlert = false;
    }

    openAlert() {
        this.showAlert = true;
    }

    loadComments() {
        this.httpClient.get('assets/data/client-comments.json').subscribe((clientComments: ClientComment[]) => {
            this.clientComments = clientComments;
        })
    }
}
