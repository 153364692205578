import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    focus;
    focus1;
    focus2;
    showAlert = false;

    constructor() {
    }

    ngOnInit() {
    }

    closeAlert() {
        this.showAlert = false;
    }

    openAlert() {
        this.showAlert = true;
    }

    sendEmail() {

    }
}
